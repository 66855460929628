/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--blue-color: #03003E;
	--yellow-color: #FFDE59;
	--black-color: #333338;
	--white-color: #FFF;
	--gray-color: #E6E6E6;
	--light-gray: #F2F2F2;
	--light-blue: #100A73;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

/********************/
/*       HOME       */
/********************/
.container-home-swiper {
	height: 100vh;
}

.container-dark {
	background-color: var(--black-color);
}

.container-banner {
	padding: 0 12rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
}

.container-main-title {
	display: flex;
	gap: 2rem;
	flex-direction: column;
	width: 40rem;
	color: var(--white-color);
}

.animated-title {
	text-transform: uppercase;
}

.main-text {
	display: flex;
	padding-left: 5rem;
	font-size: 1.3rem;
	width: 30rem;
	text-align: center;
}

/* Call-To-Action */
.container-call-to-action{
	height: 90vh;
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(51,51,56,1) 77%);
	display: flex;
	gap: 5rem;
	justify-content: center;
	align-items: center;
}

.call-to-action-text {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	padding: 3rem 8rem 8rem;
	width: 50rem;
	align-items: center;
	color: var(--white-color);
	gap: 2rem;
}

.call-to-action-text h3 {
	font-size: 3rem;
	text-transform: uppercase;
}

.about-link {
	display: flex;
	padding: .8rem;
	font-weight: 500;
	text-transform: uppercase;
	color: var(--black-color);
	background-color: var(--white-color);
	transition: all .5s ease;
}

.about-link:hover{
	background-color: var(--black-color);
	color: var(--white-color);
}

.call-to-action-image {
	width: 40rem;
	height: 40rem;
}

.call-to-action-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* Servicios */
.container-services {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 3rem;
	padding: 10rem 3rem;
}

.container-service-title {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 3rem;
	width: 100%;
	text-transform: uppercase;
}

.container-service-paragraph {
	display: flex;
	flex-direction: column;
	padding: 0;
	gap: 3rem;
	margin-left: 2rem;
}

.services-items {
	display: flex;
	gap: 3rem;
	align-items: center;
}

.doble-flecha {
	color: var(--white-color);
	font-size: 5rem;
	cursor: pointer;
}

.services-item-text {
	display: flex;
	flex-direction: column;
	gap: .8rem;
	width: 70%;
	transition: all .5s ease;
}

.color-title,
.color-text {
	color: var(--white-color);
}

/* About */
.container-aboutus{
	min-height: 90vh;
	height: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-about.webp');
	background-size: cover;
}

.about-text {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	padding: 3rem 8rem 8rem;
	width: 40rem;
	align-items: flex-start;
	color: var(--white-color);
	gap: .8rem;
}

.about-text h3 {
	font-size: 3rem;
	text-transform: uppercase;
}

.about-link {
	display: flex;
	padding: .8rem;
	font-weight: 500;
	text-transform: uppercase;
	color: var(--black-color);
	background-color: var(--white-color);
	transition: all .5s ease;
}

.about-link:hover{
	background-color: var(--black-color);
	color: var(--white-color);
}

/* Franchise */
.container-home-franchises {
	background-color: var(--blue-color);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 12rem;
	gap: 2rem;
}

.contain-home-franchises {
	color: var(--white-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;
	text-align: center;
}

.contain-home-franchises h3 {
	font-size: 2.5rem;
	text-transform: uppercase;
}

.container-franchise-image {
	width: 40rem;
}

.container-franchise-image img {
	width: 100%;
}

/* Process */
.container-gray {
	background-color: #BBB;
}

.container-process {
	padding: 7rem 0;
}

.container-process-title {
	display: flex;
	padding: 1rem 0 5rem;
	justify-content: center;
	color: var(--white-color);
	text-transform: uppercase;
}

.process-title {
	font-size: 2.5rem;
	color: var(--white-color);
}

.process-items {
	display: flex;
	padding-left: 20rem;
	justify-content: flex-start;
	align-items: center;
	gap: 5rem;
}

.process-items2 {
	display: flex;
	justify-content: flex-end;
	padding-right: 20rem;
	align-items: center;
	gap: 5rem;
}

.process-templates {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.process-icon,
.process-text {
	color: var(--black-color);
}

.process-icon {
	font-size: 5rem;
	border: 2px solid var(--white-color);
	border-radius: 1rem;
	padding: .8rem 2.5rem .3rem 3rem;
}

.process-text {
	display: flex;
	text-align: center;
	width: 25%;
}

/* Process */
.container-home-process {
	display: flex;
	flex-direction: column;
	gap: 5rem;
	background-color: #BBB;
	align-items: center;
	justify-content: center;
	padding: 5rem;
}

.contain-home-process {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	align-items: center;
	justify-content: center;
}

.container-ecommerce-payment {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--black-color);
	gap: 10rem;
	padding: 15rem;
}

.ecommerce-payment-title {
	font-size: 2.5rem;
	color: var(--white-color);
}

.container-payment-methods {
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
}

.contain-ecommerce-payment {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 30rem;
}

.payment-content {
	position: relative;
	width: 25rem;
	height: 25rem;
	background-color: var(--white-color);
}

.payment-back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 10px var(--gray-color), 0 0 40px var(--gray-color), 0 0 80px var(--gray-color);
}

.payment-back img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.payment-box {
	position: absolute;
	top: 2rem;
	bottom: 2rem;
	right: 2rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000000DD;
	transition: transform .5s;
	transform-origin: right;
	transform: scaleX(0);
}

.payment-content:hover .payment-box {
	transition: transform 0.5s ease;
	transform-origin: left;
	transform: scaleX(1);
}

.payment-text {
	position: relative;
	padding: 1rem 4rem;
	opacity: 0;
	transition: .5s;
	display: flex;
	flex-direction: column;
	gap: .3rem;
}

.payment-content:hover .payment-box .payment-text {
	opacity: 1;
	transition-delay: .5s;
}

.payment-text h4 {
	color: var(--white-color);
	margin-bottom: .5rem;
	font-size: 1.2rem;
}

.payment-text p {
	color: var(--white-color);
	font-size: .9rem;
}

.text-with-chevron {
	display: flex;
	gap: .5rem;
	align-items: center;
}

/********************/
/*      ABOUT       */
/********************/

.background-gray {
	background-color: var(--gray-color);
}

.background-blue {
	background-color: var(--blue-color);
}

.container-intro {
	display: flex;
	flex-direction: column;
	padding: 5rem 3rem;
	min-height: 92vh;
	height: 100%;
}

.animated-intro {
	text-transform: uppercase;
}

.intro-items {
	display: flex;
	align-items: center;
	gap: 2rem;
}

.intro-items p {
	padding-left: 2rem;
	font-size: 1.3rem;
}

.intro-paragraphs {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.intro-items img {
	width: 50%;
}

.container-origin {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	min-height: 70vh;
	height: 100%;
	padding: 5rem 3rem;
	gap: 5rem;
	color: var(--white-color);
}

.origin-title {
	width: 100%;
	text-transform: uppercase;
	font-size: 3rem;
	padding: 1rem;
}

.origin-text {
	display: flex;
	font-size: 1.3rem;
	padding: 3rem;
}

.container-how {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	min-height: 70vh;
	height: 100%;
	padding: 5rem 3rem;
	gap: 5rem;
	color: var(--white-color);
}

.how-title {
	width: 100%;
	text-transform: uppercase;
	font-size: 3rem;
	padding: 1rem;
}

.how-text {
	display: flex;
	font-size: 1.3rem;
	padding: 3rem;
}

.container-considerations {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5rem 3rem;
	gap: 5rem;
}

.entity-title {
	font-size: 1.5rem;
}

.container-entities {
	display: flex;
	padding: 2rem 0;
	gap: 1.5rem;
	justify-content: center;
	align-items: flex-start;
}

.container-entity {
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.entity-image {
	width: 35rem;
}

.contain-toggle {
	font-size: 1.3rem;
	display: flex;
	gap: 1rem;
	align-items: center;
	cursor: pointer;
}

.advantage-item {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem 3rem;
}

.advantage-paragraph {
	font-size: 1.2rem;
}

.container-advantages h5 {
	font-size: 1.3rem;
}

.considerations-title {
	text-transform: uppercase;
	font-size: 3rem;
	padding: 1rem;
}

.container-conclusions {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 70vh;
	height: 100%;
	padding: 5rem 3rem;
	gap: 5rem;
}

.conclusions-title {
	text-transform: uppercase;
	font-size: 3rem;
	padding: 1rem;
	color: var(--white-color);
}

.conclusions-text {
	display: flex;
	font-size: 1.3rem;
	padding: 3rem;
	text-align: center;
	color: var(--white-color);
}

.container-faqs {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	padding: 5rem 0;
}

.faqs-title {
	font-size: 3rem;
	text-transform: uppercase;
}

.container-back-about {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/final-about.webp');
	background-size: cover;
}

/********************/
/*     CONTACT      */
/********************/

.container-contact-home {
	background-color: var(--gray-color);
}

.container-contact {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact-title {
	margin-top: 5rem;
}

.contact-title-animated {
	font-size: 2.8rem;
	text-transform: uppercase;
}

.section-contact {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6rem 4rem;
	margin-bottom: 5rem;
}

.contact-black-info {
	background-color: var(--black-color);
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 2.1rem 1rem;
}

.container-grid-contact {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(2, 1fr);
}

.container-maps {
	width: 70%;
}

.container-contact-items {
	display: flex;
	gap: 1rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.container-contact-items h4	{
	color: var(--white-color);
}

.text-contact-item {
	text-align: center;
	color: var(--white-color);
}

.contact-icon {
	color: var(--white-color);
	padding: 1rem;
	font-size: 5rem;
	border: 2px solid var(--white-color);
	border-radius: 2.4rem;
}

.container-social-networks {
	display: flex;
	gap: 1rem;
}

.contact-social-instagram,
.contact-social {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .4rem;
	font-size: 1.8rem;
	color: var(--white-color);
	transition: all .5s ease;
	border-radius: 2rem;
}

.contact-social:hover {
	background-color: var(--white-color);
	color: #0866FF;
}

.contact-social-instagram:hover {
	background-color: var(--white-color);
	color: #DD2A7B;
}

.contact-form-section {
	display: flex;
	width: 100%;
	background-color: var(--gray-color);
}

/********************/
/*     POLICIES     */
/********************/

.container-policies {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	padding: 5rem 8rem;
}

.policies-spacing {
	padding-left: 1.5rem;
}

.container-policies p {
	line-height: 1.7rem;
}

.subtitle-policies {
	font-weight: 600;
	gap: .2rem;
}

.subtitle-policies span{
	font-weight: 400;
}

.icon-policies {
	padding-right: .4rem;
	font-size: .8rem;
}

.pdf-generate {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 2rem;
}

.print-button {
	padding: .8rem 1.5rem;
	font-size: 1.1rem;
	font-weight: 500;
	border: none;
	background-color: var(--blue-color);
	color: var(--white-color);
	transition: all .5s ease;
	cursor: pointer;
}

.print-button:hover {
	background-color: var(--light-blue);
}

.container-error404 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 67.8vh;
	height: 100%;
}

/********************/
/*  NETBOOK-SCREEN  */
/********************/

@media screen and (max-width: 1450px) {
	.container-banner {
		padding: 0 5rem;
	}
}

/********************/
/*  TABLET-SCREEN   */
/********************/

@media screen and (max-width: 1200px) {
	.container-main-title {
		max-width: 30rem;
		width: 100%;
	}

	.main-text {
		max-width: 25rem;
		width: 100%;
	}
}

@media screen and (max-width: 900px) {
	.banner-main {
		background-image: url('../images/banner-movil.png');
		background-size: cover;
	}

	.container-main-title {
		display: flex;
		width: 100%;
	}

	.main-text {
		padding: 0;
		width: 100%;
	}
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container {
		width: 100%;
	}

	/*      BANNER      */

	.banner-main {
		background-image: url('../images/banner-movil.png');
		background-size: cover;
	}

	.container-banner {
		padding: 20rem 0;
	}

	.container-main-title {
		display: flex;
		width: 100%;
	}

	.main-text {
		padding: 0;
		width: 100%;
	}

	/*  CALL-TO-ACTION  */

	.container-call-to-action{
		height: 100%;
		display: flex;
		flex-direction: column-reverse;
		gap: 5rem;
		justify-content: center;
		align-items: center;
	}

	.call-to-action-text {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		padding: 3rem 3rem 8rem;
		width: 100%;
		align-items: center;
		text-align: center;
		color: var(--white-color);
		gap: 2rem;
	}

	.call-to-action-image {
		width: 100%;
		height: 100%;
	}

	/*     SERVICES     */
	.container-services {
		display: flex;
		flex-direction: column;
		padding: 3rem 0;
	}

	.container-service-paragraph {
		display: flex;
		flex-direction: column;
		padding: 0 2rem;
		gap: 4rem;
	}

	.container-service-title {
		width: 100%;
	}

	.doble-flecha {
		font-size: 3rem;
	}

	.services-item-text {
		width: 70%;
	}

	/* Main-About */
	.about-text {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 2rem 4rem 4rem;
		height: 85vh;
	}

	.about-text h3 {
		font-size: 2.5rem;
	}

	/* Franchise */
	.container-home-franchises {
		display: flex;
		flex-direction: column;
		gap: 5rem;
		padding: 5rem 2rem;
	}

	.container-franchise-image {
		width: 100%;
	}

	/*     PROCESS    */
	.container-home-process {
		padding: 5rem 1rem;
		align-items: center;
		justify-content: center;
	}

	.contain-home-process {
		align-items: center;
		justify-content: center;
		gap: 5rem;
	}

	.container-process-title {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem;
	}

	.process-title {
		text-align: center;
		font-size: 2.5rem;
	}

	.process-templates {
		display: flex;
		width: 100%;
	}

	.process-items,
	.process-items2 {
		padding: 1.5rem;
		gap: 1rem;
	}

	.process-text {
		width: 40%;
	}

	.contain-ecommerce-payment {
		width: 100%;
	}

	/*     ABOUT      */

	.container-intro {
		height: 100%;
		padding-top: 7rem;
	}

	.intro-items {
		display: flex;
		flex-direction: column-reverse;
	}

	.intro-items p {
		width: 100%;
		text-align: center;
		padding: 0;
	}

	.intro-items img {
		width: 100%;
	}

	.container-origin {
		display: flex;
		flex-direction: column-reverse;
		height: 100%;
		padding: 5rem 3rem;
		gap: 3rem;
	}

	.origin-title {
		font-size: 2.5rem;
		text-align: center;
		padding: 1rem 0;
	}

	.origin-text {
		padding: 2rem 0;
		text-align: center;
	}

	.container-how {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 5rem 3rem;
		gap: 3rem;
	}

	.how-title {
		font-size: 2.5rem;
		text-align: center;
	}

	.how-text {
		padding: 2rem 0;
		text-align: center;
	}

	.container-considerations {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 5rem 2rem;
		gap: 3rem;
	}

	.considerations-title {
		font-size: 2rem;
		text-align: center;
	}

	.considerations-text {
		padding: 2rem;
		text-align: center;
	}

	.entity-title {
		font-size: 1.5rem;
	}

	.container-entities {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 2rem 0;
		gap: 1.5rem;
		justify-content: center;
		align-items: center;
	}

	.container-entity {
		padding: 2rem 0;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.back-entity {
		display: flex;
		flex-direction: column-reverse;
	}

	.entity-image {
		width: 10rem;
	}

	.container-conclusions {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		padding: 5rem 3rem;
		gap: 5rem;
	}

	.conclusions-title {
		text-transform: uppercase;
		font-size: 2.5rem;
		text-align: center;
		padding: 1rem;
		color: var(--white-color);
	}

	.conclusions-text {
		display: flex;
		font-size: 1.3rem;
		padding: 0;
		text-align: center;
		color: var(--white-color);
	}

	.faqs-title {
		font-size: 2.5rem;
		text-align: center;
	}

	/*    CONTACT     */

	.container-contact {
		width: 100%;
	}

	.section-contact {
		display: flex;
		flex-direction: column-reverse;
		padding: 6rem 0;
		width: 100%;
		padding-bottom: 0;
	}

	.container-grid-contact {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.container-maps {
		width: 100%;
	}

	/*     POLICIES     */

	.container-policies {
		padding: 5rem 2rem;
	}

	.container-error404 {
		width: 100%;
	}

	.container-error404 img{
		width: 100%;
	}
}