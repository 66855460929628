/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--blue-color: #03003E;
	--yellow-color: #FFDE59;
	--black-color: #333338;
	--white-color: #FFF;
	--gray-color: #E6E6E6;
	--light-gray: #F2F2F2;
	--light-blue: #100A73;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.img-sign {
	width: 7.8rem;
}

/********************/
/*      SIGNUP      */
/********************/

.container-form-auth {
	background-color: var(--gray-color);
	display: flex;
	flex-direction: column;
	padding: 3rem;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.form-content {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
}

.form-section-auth {
	display: flex;
	flex-direction: column;
	width: 30rem;
}

.input-form {
	background-color: var(--light-gray);
	padding: 1rem;
	border: 2px solid transparent;
	transition: all .5s ease;
}

.input-form:focus {
	outline: none;
	border-bottom: 2px solid var(--blue-color);
}

.button-form {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0A0A0A;
	padding: .8rem;
	width: 15rem;
	border: none;
	outline: none;
	color: var(--white-color);
	font-family: inherit;
	font-size: 1.2rem;
	text-transform: uppercase;
	font-weight: 600;
	transition: all .5s ease;
	cursor: pointer;
}

.not-button-form {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--black-color);
	padding: .8rem;
	width: 15rem;
	border: none;
	outline: none;
	color: var(--white-color);
	font-family: inherit;
	font-size: 1.2rem;
	text-transform: uppercase;
	font-weight: 600;
	cursor: default;
}

.button-form:hover {
	background-color: var(--black-color);
}

.required-fields-link,
.forgot-password {
	font-weight: 600;
	color: var(--black-color);
	transition: all .3s ease;
}

.required-fields-link:hover,
.forgot-password:hover {
	color: var(--light-blue);
}

.container-to-signup {
	display: flex;
	gap: .7rem;
}

.error-message {
	color: #f00;
}

.container-activate {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 67.8vh;
	height: 100%;
}

.password-button {
	position: relative;
}

.password-show-hide {
	position: absolute;
	top: 2.6rem;
	right: 1rem;
	font-size: 1.2rem;
	color: var(--black-color);
}

.required-gap {
	display: flex;
	gap: 1rem;
}

/********************/
/*  RESET-PASSWORD  */
/********************/

.container-reset-password {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--gray-color);
	min-height: 72vh;
	height: 100%;
	padding: 1rem;
}

/********************/
/*     UPLOAD-ID    */
/********************/

.card-image-preview {
	display: flex;
	flex-direction: column;
	gap: 5rem;
	justify-content: center;
	align-items: center;
}

.form-card-content {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	justify-content: center;
	align-items: center;
}

.selfiepreview-image-contain {
	min-width: 25rem;
	width: 25rem;
	min-height: 25rem;
	height: 25rem;
	object-fit: cover;
	position: relative;
	border: 2px solid var(--gray-color);
}

.selfiepreview-image-contain img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.selfie-placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25rem;
	height: 25rem;
	background-color: var(--light-gray);
}

.cardpreview-image-container {
	min-width: 24rem;
	width: 24rem;
	min-height: 15rem;
	height: 15rem;
	object-fit: cover;
	position: relative;
	border: 2px solid var(--gray-color);
}

.cardpreview-image-container img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.container-card-id {
	display: flex;
	gap: 5rem;
}

.card-placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24rem;
	height: 15rem;
	background-color: var(--light-gray);
}

.delete-button {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 2rem;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.delete-button:hover {
	color: var(--black-color);
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container {
		width: 100%;
	}

	.container-form-auth {
		width: 100%;
		padding: 8rem 3rem;
		height: 100%;
		padding-bottom: 5.6rem;
	}

	.contact-form {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 3rem 1rem;
		flex-wrap: unset;
		height: 100%;
	}

	.title-contact {
		display: flex;
		padding: 3rem;
		width: 100%;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.title-contact p {
		max-width: 100%;
		text-align: center;
	}

	.submit-button {
		display: flex;
		width: 5rem;
	}

	.form-contact {
		width: 100%;
	}

	.form-content {
		width: 100%;
	}

	.form-section,
	.form-section-auth {
		width: 100%;
	}

	.required-fields {
		text-align: center;
	}

	/*     GOOD-FORM    */

	.good-form {
		width: 100%;
		padding: 0 2rem;
	}

	.form-section-good {
		width: 100%;
	}

	.submit-button {
		width: 12rem;
	}

	.container-card-id {
		flex-direction: column;
	}
}