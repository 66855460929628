/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--blue-color: #03003E;
	--yellow-color: #FFDE59;
	--black-color: #333338;
	--white-color: #FFF;
	--gray-color: #E6E6E6;
	--light-gray: #F2F2F2;
	--light-blue: #100A73;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

/********************/
/*       ALERT      */
/********************/

.container-alert {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .5rem;
	gap: 2rem;
	top: 5rem;
	z-index: 900;
	left: 50%;
	transform: translateX(-50%);
}

.correct-alert {
	color: #19C948;
}

.incorrect-alert {
	color: #D0293F;
}

.good {
	color: #19C948;
}

.bad {
	color: #D0293F;
}

/********************/
/*     WHATSAPP     */
/********************/

.whatsapp-button {
	position: fixed;
	bottom: 40px;
	right: 40px;
	display: flex;
	gap: 1rem;
	background-color: var(--blue-color);
	align-items: center;
	justify-content: center;
	padding: .5rem 1.5rem;
	color: var(--white-color);
	border-radius: 2rem;
	z-index: 10;
	transition: all .5s ease;
}

.whatsapp-button:hover {
	background-color: var(--light-blue);
}

.whatsapp-icon {
	font-size: 2rem;
	display: flex;
	align-items: center;
}

/*********************/
/*   ANIMATED TEXT   */
/*********************/

.animated-text {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	align-items: center;
	font-size: 3rem;
	overflow: hidden;
}

.animate-text {
	display: inline-block;
}

/********************/
/*      SWIPER      */
/********************/

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-banner-home {
	padding: 15rem 0;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	color: var(--white-color);
	height: 100%;
	width: 100%;
}

.swiper-banner-home h3 {
	font-size: 3rem;
	font-weight: 400;
}

.swiper-banner-home p {
	width: 100%;
	max-width: 40rem;
	height: 4rem;
}

.earring {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-home1.webp');
	background-size: cover;
	background-position: center;
}

.necklace {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-home2.webp');
	background-size: cover;
	background-position: center;
}

.bracelet {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-home3.webp');
	background-size: cover;
	background-position: center;
}

.link-shop-category {
	padding: .8rem 1.2rem;
	background-color: var(--white-color);
	transition: all .5s ease;
	color: var(--footer-color);
}

.link-shop-category:hover {
	background-color: var(--footer-color);
	color: var(--white-color);
}

/********************/
/*  PARALLAX-SECT   */
/********************/

.parallax-section {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
}

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/parallaxabout.webp');
  background-size: cover;
  background-attachment: fixed; /* Fija la imagen de fondo */
  z-index: -1; /* Coloca el fondo detrás del contenido */
}

.parallax-content {
  position: relative;
  z-index: 100; /* Coloca el contenido encima del fondo */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 4rem;
  padding: 0 5rem;
  color: white;
  text-align: center;
}

.parallax-content h3 {
	text-transform: uppercase;
	font-size: 3rem;
}

.parallax-content p {
	font-size: 1.3rem;
}

/********************/
/*       FAQS       */
/********************/

.container-faq {
	padding: 0 3rem;
	width: 100%;
}

.faq-item {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	padding: .5rem;
}

.faq-button {
	font-size: 1.1rem;
	background-color: var(--gray-color);
	cursor: pointer;
	border: none;
	padding: 1rem;
}

.faq-text {
	background-color: var(--light-gray);
	padding: .5rem;
}

/********************/
/*   CONTACT-FORM   */
/********************/

.contact-form {
	display: flex;
	padding: 6.5rem 5rem;
	flex-wrap: wrap;
	min-height: 100vh;
	height: 100%;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.title-contact {
	display: flex;
	flex-direction: column;
	padding: 3rem 5rem;
	width: 50%;
	gap: 1.5rem;
}

.title-contact p{
	max-width: 60%;
}

.title-contact h3 {
	font-size: 2.5rem;
	text-transform: uppercase;
}

.form-contact {
	display: flex;
	flex-direction: column;
	padding: 3rem;
	gap: 2rem;
	width: 50%;
}

.form-section {
	display: flex;
	flex-direction: column;
}

.input-form {
	background-color: var(--light-gray);
	padding: 1rem;
	border: 2px solid transparent;
	transition: all .5s ease;
}

.input-form:focus,
.input-form-message:focus {
	outline: none;
	border-bottom: 2px solid var(--blue-color);
}

.input-form-message {
	background-color: #F2F2F2;
	text-align: initial;
	height: 8rem;
	padding: 1rem;
	border: 2px solid transparent;
	transition: all .5s ease;
}

input::-webkit-input-placeholder{
	font-weight: 400;
	font-family: inherit;
	color: #888;
}

.submit-button {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0A0A0A;
	padding: .8rem;
	width: 15rem;
	border: none;
	outline: none;
	color: var(--white-color);
	font-family: inherit;
	font-size: 1.2rem;
	text-transform: uppercase;
	font-weight: 600;
	transition: all .5s ease;
	cursor: pointer;
}

.submited-form {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #19C948;
	padding: .8rem;
	width: 15rem;
	font-size: 1.2rem;
	text-transform: uppercase;
	font-weight: 600;
}

.submit-button:hover {
	background-color: var(--black-color);
}

.required-fields {
	font-size: .8rem;
	color: #000;
}

/********************/
/*   IMG-GALLERY    */
/********************/

.image-container {
	display: flex;
	width: 12rem;
	height: 12rem;
	position: relative;
}

.image-container img {
	width: 100%;
	object-fit: cover;
}

.edit-img-button {
	position: absolute;
	cursor: pointer;
	left: 12.1rem;
	font-size: 2rem;
	background-color: var(--gray-color);
	color: var#000;
	border: none;
	padding: .4rem .3rem .2rem .6rem;
	transition: all .5s ease;
	border-radius: .5rem;
}

.edit-img-button:hover {
	background-color: var(--black-color);
	color: var(--white-color);
}

.container-image-gallery {
	position: relative;
}

.chevron-gallery-left {
	position: absolute;
	cursor: pointer;
	z-index: 10;
	border: none;
	background: transparent;
	font-size: 2rem;
	top: 40%;
	left: -2rem;
}

.chevron-gallery-right {
	position: absolute;
	cursor: pointer;
	z-index: 10;
	border: none;
	background: transparent;
	font-size: 2rem;
	top: 40%;
	right: -2rem;
}

/********************/
/*  TABLET-SCREEN   */
/********************/

@media screen and (max-width: 1200px) {
	.animated-title {
		font-size: 2rem;
	}
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container {
		width: 100%;
	}

	.whatsapp-button {
		position: fixed;
		bottom: 10px;
		right: 10px;
	}

	/*  PARALLAX-SECT   */

	.parallax-section {
		height: 100%;
	}

	.parallax-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		gap: 2rem;
		padding: 5rem 3rem;
	}
}