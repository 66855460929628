/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--blue-color: #03003E;
	--yellow-color: #FFDE59;
	--black-color: #333338;
	--white-color: #FFF;
	--gray-color: #E6E6E6;
	--light-gray: #F2F2F2;
	--light-blue: #100A73;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

/********************/
/*     PROFILE      */
/********************/

.profile-div {
	display: flex;
	flex-direction: column;
	padding: 8rem;
	min-height: 72.4vh;
	height: 100%;
	gap: 2rem;
	padding-right: 20rem;
}

.container-profile {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-gap: 5rem;
}

.background-profile {
	background-color: var(--gray-color);
}

.image-profile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;
	padding-bottom: 3rem;
	font-size: 1.2rem;
}

.image-profile img {
	border-radius: 50%;
	width: 15rem;
	height: 15rem;
}

.image-profile span {
	font-weight: 500;
}

.image-profile p {
	font-size: 1rem;
}

.container-profile-forms {
	display: flex;
	flex-direction: column;
	padding: 0 2rem;
}

.tabs {
	display: flex;
	cursor: pointer;
	text-align: center;
	font-size: 1.2rem;
}

.tabs div {
	padding: .5rem 1.5rem;
}

.personal-form {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	padding: 2rem 1.5rem;
	background-color: var(--light-gray);
}

.active-tab {
	background: rgb(242,242,242);
	background: linear-gradient(180deg, rgba(242,242,242,1) 0%, rgba(170,170,170,1) 94%);
	border-bottom: 1px solid var(--dark-gray);
}

.form-label {
	display: grid;
	grid-template-columns: 2fr 6fr;
}

.form-label input {
	padding: .3rem;
	border: none;
	background-color: inherit;
	border-bottom: 1px solid #bbb;
}

.form-label-security {
	display: grid;
	grid-template-columns: 3fr 6fr;
}

.form-label-security input {
	padding: .3rem;
	border: none;
	background-color: inherit;
	border-bottom: 1px solid #bbb;
}

.form-label input:focus,
.form-label-security input:focus {
	outline: none;
	border-bottom: 1px solid var(--blue-color);
}

.password-button {
	display: flex;
	padding: .3rem;
	cursor: pointer;
}

.input-form {
	background-color: var(--light-gray);
	padding: 1rem;
	border: 2px solid transparent;
	transition: all .5s ease;
}

.input-form:focus {
	outline: none;
	border-bottom: 2px solid var(--blue-color);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
 	-webkit-appearance: none; 
	margin: 0; 
}

.save-changes {
	display: flex;
	padding: .8rem 1.8rem;
	font-size: 1rem;
	width: 15rem;
	justify-content: center;
	align-items: center;
	border: none;
	cursor: pointer;
	background-color: var(--blue-color);
	color: var(--white-color);
	font-weight: 500;
	margin: 0 auto;
	transition: all .5s ease;
}

.save-changes:hover {
	background-color: #100A73;
}

.edit-button {
	display: flex;
	padding: .8rem 1.8rem;
	font-size: 1rem;
	width: 10rem;
	justify-content: center;
	align-items: center;
	border: none;
	cursor: pointer;
	background-color: var(--blue-color);
	color: var(--white-color);
	font-weight: 500;
	margin: 0 auto;
	transition: all .5s ease;
}

.edit-button:hover,
.return-button:hover {
	background-color: #100A73;
}

.return-button {
	display: flex;
	gap: 1rem;
	padding: .8rem 1.8rem;
	font-size: 1rem;
	width: 10rem;
	justify-content: center;
	align-items: center;
	border: none;
	cursor: pointer;
	background-color: var(--blue-color);
	color: var(--white-color);
	font-weight: 500;
	margin: 0 auto;
	transition: all .5s ease;
}

.change-password-button {
	font-weight: 600;
	color: var(--black-color);
	transition: all .3s ease;
}

.change-password-button:hover {
	color: var(--light-blue);
}

/********************/
/*   EDIT-PROFILE   */
/********************/

.container-form-edit {
	background-color: var(--gray-color);
	display: flex;
	flex-direction: column;
	padding: 3rem;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.form-section-edit {
	display: flex;
	flex-direction: column;
	width: 30rem;
}

.profile-preview {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
}

.profile-placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 15rem;
	min-height: 15rem;
	border-radius: 50%;
	background-color: var(--light-gray);
}

.profile-image-container {
	min-width: 15rem;
	width: 15rem;
	min-height: 15rem;
	height: 15rem;
	align-content: center;
	justify-items: center;
	object-fit: cover;
	position: relative;
	border: 2px solid var(--gray-color);
}

.profile-image-container img {
	width: 100%;
	height: 15rem;
	object-fit: cover;
	border-radius: 50%;
}

.delete-button {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 2rem;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.delete-button:hover {
	color: var(--black-color);
}

.input-profile {
	display: flex;
	padding: 1rem;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.profile-div {
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
		padding: 8rem 1rem;
		width: 100%;
	}

	.container-profile {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.image-profile {
		display: flex;
		flex-direction: column-reverse;
	}

	.form-label-security label,
	.form-label label {
		display: none;
	}

	.form-label {
		display: flex;
		width: 100%;
	}

	.personal-form {
		width: 100%;
	}

	.container-profile-forms {
		width: 100%;
		padding: 1rem;
	}

	.tabs {
		height: 100%;
	}

	.backeground-profile {
		width: 100%;
	}

	.container-tabs {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.container-form-edit {
		padding-top: 10rem;
	}

	.form-section-edit {
		width: 100%;
	}


}