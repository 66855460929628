/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--blue-color: #03003E;
	--yellow-color: #FFDE59;
	--black-color: #333338;
	--white-color: #FFF;
	--gray-color: #E6E6E6;
	--light-gray: #F2F2F2;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

/********************/
/*      NAVBAR      */
/********************/

.container-navbar {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem 8rem;
	background-color: var(--white-color);
	z-index: 1000;
	box-shadow: 0px 2px 5px 0px rgba(71,71,71,0.75);
	-webkit-box-shadow: 0px 2px 5px 0px rgba(71,71,71,0.75);
	-moz-box-shadow: 0px 2px 5px 0px rgba(71,71,71,0.75);
}

.container-bar {
	display: flex;
	gap: 2rem;
}

.span-bar {
	height: 2px;
	display: inline-block;
	width: 0%;
	background-color: var(--blue-color);
	position: absolute;
	left: 0;
	bottom: -0.125rem;
	transition: .3s ease;
}

.container-user-button {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	color: var(--blue-color);
	border: none;
	background-color: var(--white-color);
	cursor: pointer;
	position: relative;
}

.container-user-button span {
	position: absolute;
	top: -.5rem;
	right: -.9rem;
	background-color: #FB3640;
	color: var(--white-color);
	padding: 0 .3rem;
	font-size: .9rem;
	border-radius: .5rem;
}

.menu-navbar {
	position: absolute;
	top: 4.6rem;
	right: 11rem;
	background-color: var(--white-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 10rem;
	gap: 1rem;
	padding: 1rem;
	border-radius: .8rem;
	box-shadow: 0px 2px 5px 0px rgba(71,71,71,0.75);
	-webkit-box-shadow: 0px 2px 5px 0px rgba(71,71,71,0.75);
	-moz-box-shadow: 0px 2px 5px 0px rgba(71,71,71,0.75);
}

.submenu-active {
	color: var(--blue-color);
	font-weight: 500;
}

.signout-button {
	border: none;
	background-color: transparent;
	cursor: pointer;
	font-weight: 500;
	font-size: 1rem;
}

.container-logo {
	display: flex;
	align-items: center;
	width: 190px;
	flex-direction: row;
	gap: 1rem;
	font-size: 1.1rem;
	font-weight: 500;
	color: var(--blue-color);
	text-transform: uppercase;
}

.container-logo img{
	width: 180px;
}

.container-navlinks {
	display: flex;
	gap: 2rem;
}

.navlink-social {
	display: flex;
	gap: 2rem;
}

.navlink-item {
	position: relative;
	color: var(--blue-color);
	padding: .2rem 0;
	border-radius: .8rem;
	transition: all .5s ease;
	font-weight: 500;
}

.navlink-item:hover .span-bar{
	width: 100%;
}

.active span{
	width: 100%;
}

.navlink-item:hover {
	color: var(--blue-color);
}

.active {
	color: var(--blue-color);
}

.social-active {
	background-color: var(--secundary-color);
}

.login {
	display: flex;
	padding: .5rem .7rem;
	align-items: center;
	justify-content: center;
	background-color: var(--blue-color);
	color: var(--white-color);
	transition: all .3s ease;
}

.login:hover {
	background-color: var(--gray-color);
	color: var(--blue-color);
}

#menu-toggle {
	display: none;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
}

/********************/
/*      FOOTER      */
/********************/

.container-footer {
	display: flex;
	width: 100%;
	flex-direction: column;
	background-color: var(--black-color);
	padding-top: 2rem;
}

.footer-dividers {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	padding: 0 8rem;
	justify-items: center;
}

.container-privacy {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 1rem;
}

.privacy-links {
	color: var(--white-color);
	transition: all .3s ease;
}

.privacy-links:hover {
	color: var(--gray-color);
}

.container-footer-contact {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.contact-title-footer {
	color: var(--white-color);
	font-size: 1.2rem;
	font-weight: 500;
}

.contact-text {
	color: var(--white-color);
}

.container-footer-rights {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem 0;
	gap: 1rem;
	color: var(--white-color);
}

.container-rights {
	color: var(--white-color);
}

.footer-links {
	font-weight: 500;
	color: var(--white-color);
	transition: all .5s ease;
}

.footer-links:hover {
	color: var(--yellow-color);
}

.contact-text {
	display: flex;
	gap: .5rem;
}

.footer-social-network {
	display: flex;
	gap: 1rem;
	flex-direction: column;
}

.social-media-footer {
	display: flex;
	gap: .5rem;
	justify-content: center;
	align-items: center;
}

.footer-social-instagram,
.footer-social-facebook {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .3rem;
	font-size: 1.8rem;
	color: var(--white-color);
	transition: all .5s ease;
	border-radius: 2rem;
}

.footer-social-facebook:hover {
	background-color: var(--white-color);
	color: #0866FF;
}

.footer-social-instagram:hover {
	background-color: var(--white-color);
	color: #DD2A7B;
}

.container-newsletter {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.form-newsletter {
	display: flex;
	align-items: center;
}

.newsletter-input {
	padding: .5rem 1rem;
	border: none;
	outline: none;
	border-bottom: 2px solid transparent;
	transition: all .5s ease;
}

.newsletter-input:focus {
	border-bottom: 2px solid var(--blue-color);
}

.newsletter-button {
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	padding: 0 .5rem;
	height: 2.15rem;
	border: none;
	outline: none;
	cursor: pointer;
	color: var(--white-color);
	background-color: var(--blue-color);
	transition: all .5s ease;
}

.newsletter-button:hover {
	background-color: var(--light-blue);
}

/********************/
/*  TABLET-SCREEN   */
/********************/

@media screen and (max-width: 1200px) {
	#menu-toggle {
		display: block;
	}

	.container-navlinks {
		display: none;
	}

	.menu-active {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 100px;
		background: #fff;
		justify-content: center;
		align-items: center;
		padding: 2rem 7rem;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		border-radius: 1rem;
	}

	.menu-navbar {
		top: 20rem;
		left: 50%;
		height: 5rem;
		transform: translateX(-50%);
	}

	.container-navbar {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 1rem;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;
	}

	.toggle-menu {
		font-size: 1.8rem;
		color: var(--blue-color);
	}
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	#menu-toggle {
		display: block;
	}

	.container-navlinks {
		display: none;
	}

	.menu-active {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 100px;
		background: #fff;
		justify-content: center;
		align-items: center;
		padding: 2rem 7rem;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		border-radius: 1rem;
	}

	.menu-navbar {
		top: 20rem;
		left: 50%;
		height: 5rem;
		transform: translateX(-50%);
	}

	.container-navbar {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 1rem;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;
	}

	.toggle-menu {
		font-size: 1.8rem;
		color: var(--blue-color);
	}

	/* FOOTER */
	.container-footer {
		padding: 2rem 0 1rem;
		gap: 1rem;
	}

	.footer-dividers {
		display: flex;
		flex-direction: column;
		padding: 0 1rem;
		gap: 1.5rem;
	}

	.container-footer-contact {
		justify-content: center;
		align-items: center;
	}

	.container-privacy {
		text-align: center;
		justify-content: center;
		gap: 2rem;
	}

	.container-rights {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.contact-text {
		font-size: .9rem;
	}

	.container-footer-rights {
		display: flex;
		flex-direction: column;
	}

	.footer-social-network {
		align-items: center;
	}

	.container-newsletter {
		align-items: center;
	}
}