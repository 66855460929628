/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--blue-color: #03003E;
	--yellow-color: #FFDE59;
	--black-color: #333338;
	--white-color: #FFF;
	--gray-color: #E6E6E6;
	--light-gray: #F2F2F2;
	--light-blue: #100A73;
	--light-black: #555555;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.img-sign {
	width: 7.8rem;
}

/********************/
/*    FRANCHISE     */
/********************/

/* Work-With-Us */
.container-background-work {
	background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../images/franchise-banner.webp');
	background-repeat: no-repeat;
	background-size: cover;
}

.contain-franchise-work {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5rem;
	padding: 8rem 0;
}

.contain-franchise-work h2 {
	font-size: 3rem;
	color: var(--white-color);
	text-transform: uppercase;
}

.container-work-image {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
	width: 15rem;
}

.container-work-image img {
	width: 100%;
}

.welcome-text {
	width: 40rem;
	color: var(--white-color);
	font-size: 1.2rem;
	text-align: center;
}

.title-franchise-rbm {
	text-transform: uppercase;
	font-size: 2rem;
	color: var(--white-color);
	text-align: center;
}

/* Benefits */
.container-franchise-benefits {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8rem 0;
	gap: 7rem;
	background-color: var(--black-color);
}

.container-franchise-benefits h2 {
	font-size: 3rem;
	color: var(--yellow-color);
	text-transform: uppercase;
}

.contain-franchise-benefits {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;

}

.boxes-benefits {
	display: flex;
	flex-direction: column;
	width: 20rem;
	padding: 3rem 1.5rem;
	gap: 3rem;
	align-items: center;
	color: var(--yellow-color);
	background-color: var(--light-black);
	transition: all 0.5s ease;
	box-shadow: 0 20px 50px rgba(0,0,0,.8);
}

.boxes-benefits h4 {
	text-align: center;
	font-size: 1.4rem;
	height: 4rem;
}

.boxes-benefits:hover {
	color: var(--white-color);
	background-color: var(--light-blue);
}

.benefits-icons {
	font-size: 3rem;
}

/* Compromise */
.container-franchise-compromise {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 2rem;
	justify-content: center;
	align-items: center;
	background-color: var(--light-black);
	color: var(--white-color);
}

.contain-franchise-compromise {
	padding: 0 25rem;
	display: flex;
	flex-direction: column;
	gap: .8rem;
	text-align: center;
}

.contain-franchise-compromise h4 {
	font-size: 1.5rem;
}

/* Profit */
.container-franchise-profit {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	min-height: 90vh;
	padding: 5rem;
	background-color: var(--light-gray);
}

.container-franchise-profit h2 {
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-size: 3rem;
	padding: 0 5rem;
	text-align: center;
}

.container-profit-image {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50rem;
}

.container-profit-image img {
	width: 100%;
}

/* Select */
.container-franchise-select {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	padding: 5rem 12rem;
	justify-content: center;
	align-items: center;
	background-color: var(--light-gray);
}

.container-franchise-select h2 {
	font-size: 3rem;
	text-transform: uppercase;
}

.container-grid-animation {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
}

.animation-content {
	position: relative;
}

.animation-face {
	width: 20rem;
	height: 15rem;
	transition: all .4s ease;
}

.animation-face.face-1 {
	position: relative;
	background: var(--light-black);
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	z-index: 1;
	transform: translateY(100px);
}

.animation-content:hover .animation-face.face-1 {
	transform: translateY(0);
	background: var(--light-blue);
  	box-shadow:
    	0 0 50px var(--blue-color),
    	-10px 0 80px var(--blue-color),
    	10px 0 80px var(--light-blue);
}

.animation-face.face-1 .content {
	opacity: .3;
	transition: all .5s ease;
	text-align: center;
	color: var(--yellow-color);
}

.animation-content:hover .animation-face.face-1 .content {
	opacity: 1;
}

.animation-face.face-2 {
	position: relative;
	background: var(--text-color);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	box-sizing: border-box;
	box-shadow: 0 20px 50px rgba(0,0,0,.8);
  	transform: translateY(-140px);
}

.animation-content:hover .animation-face.face-2 {
	transform: translateY(0);
}

.animation-face.face-2 .content {
	color: #000;
}

.content-img {
	width: 100%;
	height: 100%;
}

.content-opt-img {
	width: 100%;
	height: 100%;
	padding: 2rem;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	/* Work */
	.contain-franchise-work h2 {
		text-align: center;
	}

	.welcome-text {
		width: 100%;
		padding: 0 1rem;
	}

	/* Benefits */
	.container-franchise-benefits {
		padding: 8rem 1rem;
	}

	.container-franchise-benefits h2 {
		text-align: center;
	}

	.contain-franchise-benefits {
		justify-content: center;
		align-items: center;
	}

	/* Profit */
	.container-franchise-profit {
		display: flex;
		flex-direction: column;
		padding: 7rem 1rem;
		gap: 4rem;
	}

	.container-profit-image {
		width: 100%;
	}

	/* Compromise */
	.contain-franchise-compromise {
		padding: 2rem 0;
	}
}