/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--blue-color: #03003E;
	--yellow-color: #FFDE59;
	--black-color: #333338;
	--white-color: #FFF;
	--gray-color: #E6E6E6;
	--light-gray: #F2F2F2;
	--light-blue: #100A73;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

/********************/
/*      GOODS       */
/********************/

.container-good-page {
	padding: 5rem 0;
	background-color: var(--light-gray);
	height: 100%;
	min-height: 67.8vh;
}

.goods-page {
	display: flex;
	flex-direction: column;
	gap: 3rem 0;
	padding: 8rem 0;
	padding-bottom: 15rem;
}

.goods-table {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
}

.table-header {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	text-align: center;
	min-width: 30rem;
	padding: 1rem 0;
	background-color: var(--gray-color);
	font-weight: 500;
	border: 1px solid #aaa;
}

.table-row {
	display: grid;
	padding: 1rem 0;
	justify-items: center;
	align-items: center;
	grid-template-columns: repeat(3, 1fr);
	min-width: 30rem;
	text-align: center;
	max-width: 100rem;
	background-color: var(--white-color);
	border: 1px solid #aaa;
}

.link-to-detail {
	font-weight: 500;
	transition: all .3s ease;
	color: var(--black-color);
}

.link-to-detail:hover {
	color: #4062BB;
}

.good-link {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 15rem;
	padding: .8rem 1.8rem;
	background-color: #0A0A0A;
	color: var(--white-color);
	font-weight: 500;
	margin: 0 auto;
	transition: all .3s ease;
}

.good-link:hover {
	background-color: var(--black-color);
}

.good-img {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5rem;
	height: 5rem;
}

.good-img img {
	width: 100%;
	object-fit: contain;
}

.row-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.link-to-upload {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: .8rem;
	text-align: center;
	padding: 2rem 0;
}

/********************/
/*  GOOD-REGISTER   */
/********************/

.container-good-register {
	display: flex;
	flex-direction: column;
	padding: 5rem 0;
	gap: 3rem;
}

/********************/
/*     NEW-GOOD     */
/********************/

.container-upload-form{
	display: flex;
	flex-direction: column;
	padding: 3rem 0;
	background-color: var(--gray-color);
}

.good-form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
}

.form-section-good {
	display: flex;
	flex-direction: column;
	width: 40rem;
}

.preview {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;
}

.newgood-image-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

.image-preview {
	display: flex;
	gap: 1.5rem;
}

.placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 15rem;
	min-height: 15rem;
	background-color: var(--light-gray);
}

.preview-image-container {
	min-width: 15rem;
	width: 15rem;
	min-height: 15rem;
	height: 15rem;
	object-fit: cover;
	position: relative;
	border: 2px solid var(--gray-color);
}

.preview-image-container img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.delete-button {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 2rem;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.delete-button:hover {
	color: var(--black-color);
}

.continue-button {
	font-size: 1.5rem;
	font-weight: 500;
	color: #000;
	transition: all .5s ease;
}

.continue-button:hover {
	color: var(--black-color);
}

.image-upload {
	display: flex;
}

.main-image-contain {
	display: flex;
	flex-direction: column;
}

.privacy-goods {
	font-weight: 600;
	color: #000	;
	transition: all .3s ease;
}

.privacy-goods:hover {
	color: var(--light-blue);
}

/********************/
/*  GOODS-DETAILS   */
/********************/

.container-details {
	display: grid;
	padding: 3rem;
	grid-gap: 2rem;
	grid-template-columns: 3fr 1fr;
}

.good-profile {
	display: flex;
	flex-direction: column;
	background-color: var(--gray-color);
	padding: 2rem;
	border-radius: 1rem;
	gap: 2rem;
}

.good-profile-info {
	display: flex;
	padding-left: 3rem;
	flex-direction: column;
	gap: 1.5rem;
	font-size: 1.2rem;
}

.good-profile-img {
	width: 18rem;
	height: 18rem;
}

.good-profile-img img {
	border-radius: 50%;
	width: 100%;
	height: 100%;
}

.go-to-pay {
	display: flex;
	background-color: var(--blue-color);
	padding: .8rem 1rem;
	text-align: center;
	color: var(--white-color);
	border-radius: .5rem;
	transition: all .5s ease;
	cursor: pointer;
	font-size: 1rem;
}

.go-to-pay:hover {
	color: var(--white-color);
	background-color: #19C948;
}

.add-cart-button {
	display: flex;
	background-color: var(--blue-color);
	padding: .8rem 1rem;
	text-align: center;
	color: var(--white-color);
	border-radius: .5rem;
	transition: all .5s ease;
	border: none;
	cursor: pointer;
	font-size: 1rem;
}

.add-cart-button:hover {
	color: var(--white-color);
	background-color: #19C948;
}

.previous-owner {
	display: flex;
	padding: 0 2rem;
}

.contain-pay-link {
	display: flex;
	padding: 0 2rem;
}

.container-info {
	display: flex;
	padding: 2rem;
	border-radius: 1rem;
	gap: 2rem;
}

.good-status {
	padding: 0 2rem;
}

.good-detail {
	padding-top: 2rem;
}

.good-items {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
	font-size: 1.2rem;
	gap: 1.2rem;
	background-color: var(--gray-color);
	border-radius: 1rem;
}

.good-items p {
	text-align: center;
}

.container-good-image {
	display: flex;
	width: 15rem;
}

.good-detail-img {
	width: 100%;
	object-fit: cover;
}

.actual-status {
	font-size: 1.2rem;
}

.previous-owner-title {
	font-size: 1.2rem;
}

.input-details {
	display: flex;
	padding: .5rem 1.5rem;
}

.form-details {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.button-details {
	display: flex;
	background-color: #0A0A0A;
	color: var(--white-color);
	font-size: 1rem;
	padding: .8rem 1.8rem;
	font-weight: 500;
	border: none;
	cursor: pointer;
	transition: all .5s ease;
}

.button-details:hover {
	background-color: var(--black-color);
}

.return-button:hover {
	background-color: #100A73;
}

.return-button {
	display: flex;
	gap: 1rem;
	padding: .8rem 1.8rem;
	font-size: 1rem;
	width: 10rem;
	justify-content: center;
	align-items: center;
	border: none;
	cursor: pointer;
	background-color: var(--blue-color);
	color: var(--white-color);
	font-weight: 500;
	margin: 0 auto;
	transition: all .5s ease;
}

/********************/
/*      PAYMENT     */
/********************/

.container-payment {
	display: flex;
	padding: 2rem;
	background-color: var(--light-gray);
}

.container-pay-config {
	display: flex;
	flex-direction: column;
	width: 70rem;
	justify-content: flex-start;
	align-items: center;
	padding: 4rem;
	height: 65.1vh;
	border-radius: 2rem;
	background-color: var(--white-color);
	gap: 1rem;
	position: relative;
}

.payment-detail {
	display: flex;
	gap: 3rem;
	align-items: flex-start;
}

.payment-image {
	width: 23rem;
	height: 23rem;
}

.payment-image img{
	border: 1px solid var(--gray-color);
	width: 100%;
	object-fit: cover;
}

.good-view {
	display: flex;
	flex-direction: column;
	padding: 1rem 3rem;
	justify-content: center;
	align-items: flex-start;
	font-size: 1.2rem;
	gap: 3rem;
}

.confirm-button {
	display: flex;
	gap: 1rem;
	border-radius: .5rem;
	padding: .8rem 1.8rem;
	font-size: 1rem;
	width: 10rem;
	justify-content: center;
	align-items: center;
	border: none;
	cursor: pointer;
	background-color: #0A0A0A;
	color: var(--white-color);
	font-weight: 500;
	margin: 0 auto;
	transition: all .5s ease;
}

.confirm-button:hover {
	background-color: var(--black-color);
}

.container-button-confirm {
	padding: 1rem;
}

.container-quantity {
	display: flex;
	padding: .3rem;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	border: 2px solid var(--gray-color);
}

.container-quantity p {
	font-size: 1.5rem;
}

.minus-plus-button {
	border: none;
	font-size: 1.3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	cursor: pointer;
	color: var(--black-color);
}

.pay-config-title {
	font-size: 2rem;
	font-weight: 500;
}

.pay-config-paragraph {
	font-weight: 500;
}

.pay-config-paragraph span {
	text-transform: lowercase;
	font-weight: 400;
}

.contain-price {
	font-size: 2rem;
}

.contain-total-price {
	font-size: 1.8rem;
}

.contain-total-price span {
	font-weight: 500;
}

/********************/
/*    EDIT-GOOD     */
/********************/

.container-form {
	background-color: var(--gray-color);
	display: flex;
	flex-direction: column;
	padding: 3rem;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.form-content {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
}

.form-section-cont {
	display: flex;
	flex-direction: column;
	width: 30rem;
}

.input-form {
	background-color: var(--light-gray);
	padding: 1rem;
	border: 2px solid transparent;
	transition: all .5s ease;
}

.input-form:focus {
	outline: none;
	border-bottom: 2px solid var(--blue-color);
}

.button-form {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0A0A0A;
	padding: .8rem;
	width: 15rem;
	border: none;
	outline: none;
	color: var(--white-color);
	font-family: inherit;
	font-size: 1.2rem;
	text-transform: uppercase;
	font-weight: 600;
	transition: all .5s ease;
	cursor: pointer;
}

.button-form:hover {
	background-color: var(--black-color);
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container {
		width: 100%;
	}

	.goods-page {
		width: 100%;
	}

	.table-header {
		min-width: 3rem;
		max-width: 100%;
	}

	.table-row {
		min-width: 3rem;
		max-width: 100%;
	}

	.good-link {
		width: 13rem;
	}

	/*    EDIT-GOOD     */

	.container-form {
		padding-top: 10rem;
	}

	.form-section-cont {
		width: 100%;
	}

	/*  GOOD-REGISTER   */

	.container-upload-form {
		width: 100%;
		padding: 10rem 0;
		padding-bottom: 3rem;
	}

	.form-section-good {
		width: 100%;
	}

	/*  GOODS-DETAILS   */

	.return-button {
		position: absolute;
		top: 6rem;
		left: 50%;
		transform: translateX(-50%);
	}

	.container-details {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: var(--gray-color);
	}

	.container-info {
		display: flex;
		flex-direction: column;
		border-radius: none;
		justify-content: center;
		align-items: center;
	}

	.good-profile {
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.good-profile-info {
		padding: 0;
		text-align: center;
	}

	.actual-status {
		text-align: center;
	}

	.previous-owner {
		text-align: center;
	}

	.good-detail {
		padding-top: 2.8rem;
	}

	/*      PAYMENT     */

	.container-payment {
		height: 100%;
		width: 100%;
		padding-top: 7rem;
	}

	.container-pay-config {
		padding: 2rem;
		height: 100%;
	}

	.pay-config-title {
		text-align: center;
	}

	.payment-detail {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		align-items: center;
	}

	.payment-image {
		width: 10rem;
		height: 10rem;
	}

	.good-view {
		display: flex;
		flex-direction: column;
		padding: .6rem;
		justify-content: center;
		align-items: flex-start;
		font-size: 1.2rem;
		gap: 1rem;
	}

	/*     NEW-GOOD     */

	.image-preview {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}
}