/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--blue-color: #03003E;
	--yellow-color: #FFDE59;
	--black-color: #333338;
	--white-color: #FFF;
	--gray-color: #E6E6E6;
	--light-gray: #F2F2F2;
	--light-blue: #100A73;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

/********************/
/*       CART       */
/********************/

.container-cart {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	padding-bottom: 3rem;
}

.container-cart-title {
	padding: 8rem 15rem;
	display: flex;
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-about.webp');
	color: var(--white-color);
	font-size: 2rem;
	background-size: cover;
	background-position: center;
}

.container-cart-title h2 {
	font-weight: 400;
	font-style: italic;
}

.container-cart-info {
	display: grid;
	grid-template-columns: 3fr 2fr;
	padding: 1rem 3rem;
	grid-gap: 2rem;
}

.checkout-items {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 0 3rem;
}

.checkout-item {
	display: flex;
	justify-content: space-between;
}

.checkout-button {
	display: flex;
	padding: .8rem 1.8rem;
	justify-content: center;
	border: none;
	cursor: pointer;
	background-color: var(--blue-color);
	border-radius: .5rem;
	color: var(--white-color);
	font-size: 1rem;
	transition: all .5s ease;
}

.checkout-button:hover {
	background-color: var(--light-blue);
}

.container-empty-cart {
	display: flex;
	min-height: 67.8vh;
	height: 100%;
	flex-direction: column;
}

.cart-back-to-goods {
	padding: 5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cart-link-to-goods {
	background-color: var(--blue-color);
	transition: all .5s ease;
	padding: .8rem 1.5rem;
	color: var(--white-color);
}

.cart-link-to-goods:hover {
	background-color: var(--light-blue);
}

/********************/
/*     CART-ITEM    */
/********************/

.container-cart-item {
	display: flex;
	gap: 1rem;
	padding: 2rem 1rem;
	border-top: 2px solid #ddd;
	width: 100%;
}

.cart-item-image {
	display: flex;
	width: 15rem;
	height: 15rem;
}

.cart-item-info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.cart-item-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	flex-shrink: 0;
	object-position: center;
}

.cart-item-header {
	position: relative;
	padding-right: 12rem;
	display: flex;
	gap: 3rem;
	width: 100%;
	justify-content: space-between;
}

.item-good-info {
	display: flex;
	flex-direction: column;
	width: 13rem;
	gap: 1rem;
}

.container-form-count {
	display: flex;
	justify-content: cen;
	align-items: flex-start;
}

.title-good-info {
	font-weight: 500;
}

.content-count {
	display: flex;
	gap: .5rem;
}

.button-remove-contain {
	position: absolute;
	right: 0;
	top: 0;
}

.select-count {
	display: flex;
	justify-content: center;
	font-size: 1rem;
	font-weight: 500;
	width: 3rem;
	height: 1.8rem;
	align-items: center;
	outline: none;
	border: 1px solid #000;
	cursor: pointer;
	border-radius: .3rem;
}

.update-button {
	border: none;
	font-size: 1rem;
	font-weight: 500;
	background-color: transparent;
	cursor: pointer;
	transition: all .5s ease;
}

.update-button:hover {
	color: var(--light-blue);
}

.button-remove {
	border: none;
	cursor: pointer;
	background-color: transparent;
	font-size: 2rem;
	transition: all .3s ease;
}

.button-remove:hover {
	color: #777;
}

/********************/
/*     CHECKOUT     */
/********************/

.checkout-item-info {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.checkout-item-header {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container {
		width: 100%;
	}

	.container-empty-cart {
		height: 100%;
	}

	.container-cart-title {
		width: 100%;
		padding: 8rem 2rem;
	}

	.container-cart-info {
		display: flex;
		flex-direction: column;
		padding: 1rem 3rem;
		grid-gap: 2rem;
	}

	/*     CART-ITEM    */

	.cart-item-header {
		position: relative;
		padding-right: 1rem;
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}

	.container-cart-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		padding: 2rem 1rem;
		border-top: 2px solid #ddd;
		width: 100%;
	}

	.item-good-info {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
}